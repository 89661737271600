/* Styles for the navigation bar */
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #243b2f;
  padding: 0 10px;
}
/* Styles for the navbar logo */
.navbar-logo {
  width: 140px;
  height: 70px;
}
/* Styles for the user guide icon button */
.user-guide-icon {
  background-color: transparent;
  border: none;
  color: #fffff5;
  width: auto;
  height: auto;
}
