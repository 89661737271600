@font-face {
  font-family: "GardensInkTrap-Medium";
  src: url(/public/fonts/GardensInkTrap-Medium/GardensInkTrap-Medium.woff)
    format("woff");
}
@font-face {
  font-family: "Inter-Medium";
  src: url(/public/fonts/Inter/static/Inter-Medium.ttf) format("truetype");
}

/* Styles for the body element */
body,
html {
  height: 100%;
  margin: 0;
}

/* Styles for the body element */

body {
  /* background-color: #fffff5; */
  background-image: url("/public/images/parchment-effect.jpg");
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Styles for the search bar container */
.searchbar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 160px;
}

.rbg-title {
  font-family: "GardensInkTrap-Medium";
  color: #243b2f;
  font-size: 3rem;
  margin-bottom: 20px;
  text-align: center;
}

.rbg-subtitle {
  font-size: "1.4rem";
  font-weight: normal;
  font-family: "Inter-Medium";
  color: #243b2f;
  text-align: center;
}
