.css-r2le8m-MuiGrid-root {
  padding-bottom: 10px;
}
.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  margin-top: 34px !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  margin-top: 10px;
}

.css-1equabv-MuiGrid-root {
  padding-top: 58px !important;
  padding-left: 30px !important;
}
.css-6c3i6o-MuiAutocomplete-root {
  margin-bottom: 2.5px;
}

/*autocomplete value box */
.css-feqhe6 {
  margin-top: 22px !important;
}
/*add button */
.css-4xkoi8 {
  padding-top: 46px !important;
  padding-left: 30px !important;
}

/*category*/
.css-fvipm8 {
  margin-top: 10px;
  margin-bottom: 10px;
}
