/* Styles for the search bar container */

@font-face {
  font-family: "Inter-Medium";
  src: url(/public/fonts/Inter/static/Inter-Medium.ttf) format("truetype");
}
.search-bar {
  display: flex;
  width: 700px;
  height: 2.5rem;
  border: 2px solid #243b2f;
  border-radius: 24px;
  padding: 0 15px;
  /* box-shadow: 0px 0px 8px #fffff5; */
  background-color: white;
  display: flex;
  align-items: center;
  margin: auto;
}
/* Styles for the search input field */
.search-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 16px;
  border-radius: 24px;
  font-family: "Inter-Medium";
  background-color: white;
}
/* Styles for the search icon */
#search-icon {
  color: #243b2f;
}
