/* Styles for the search result container */
@font-face {
  font-family: "Inter-Medium";
  src: url(/public/fonts/Inter/static/Inter-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: url(/public/fonts/Inter/static/Inter-Bold.ttf) format("truetype");
}
.search-result {
  padding: 10px 20px;
}

.search-result:hover {
  background-color: #f2f2f2;
}
/* Styles for the table container */
.table-container {
  margin: auto;
  border: 1px solid #fffff5;
  border-radius: 10px;
  margin-top: 50px;
  padding-top: 10px;
  overflow: hidden;
}
/* Styles for the table header container */
.table-header-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  margin-bottom: 10px;
}
/* Styles for the CSV download button container */
.download-csv-button-container {
  margin-left: 135px;
  margin-top: 80px;
}
/* Styles for the ZIP download button container */
.download-zip-button-container {
  margin-left: 20px;
  margin-top: 80px;
}
/* Styles for the "found results" text */
.found-result {
  margin-left: auto;
  margin-top: 100px;
  margin-right: 100px;
  padding-left: 20px;
  font-family: "Inter-Medium";
  color: #243b2f;
}

/* Styles for MuiDataGrid column headers */
.MuiDataGrid-columnHeader {
  background-color: #243b2f;
}
/* Styles for MuiDataGrid filler */
.MuiDataGrid-filler {
  background-color: #243b2f;
}
/* Styles for MuiDataGrid column header title */
.MuiDataGrid-columnHeaderTitleContainerContent {
  color: #fffff5;
  /* font-weight: bold; */
  font-family: "Inter-Bold";
}
.MuiDataGrid-cell {
  font-family: "Inter-Medium";
}

.MuiDataGrid-colCellWrapper {
  display: flex !important;
}

.css-qcqlck-MuiDataGrid-root .MuiDataGrid-menuIcon {
  visibility: visible !important; /* Override visibility */
  /* Add other properties as needed */
  width: auto !important;
}

.css-ptiqhd-MuiSvgIcon-root {
  color: #fffff5 !important;
}

.MuiSvgIcon-root .MuiSvgIcon-fontSizeSmall .css-1k33q06 {
  color: #fffff5 !important;
}

.css-18jtgnf .MuiDataGrid-menuIcon {
  visibility: visible !important; /* Override visibility */
  /* Add other properties as needed */
  width: auto !important;
}
.css-1j7qk7u {
  color: #fffff5 !important;
}
.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-qcqlck-MuiDataGrid-root {
  /* Add your styles for the DataGrid here */
  display: flex;
  flex-direction: column-reverse;
}

.css-18jtgnf {
  display: flex;
  flex-direction: column-reverse !important;
}
.MuiDataGrid-root
  .MuiDataGrid-root--densityStandard
  .MuiDataGrid-withBorderColor
  .css-18jtgnf {
  display: flex;
  flex-direction: column-reverse !important;
}

/*Styling for Health Icon*/
.css-1c793r8-MuiSvgIcon-root {
  margin-top: 100px !important;
  margin-right: 100px !important;
  color: #243b2f !important;
}

.css-12ovgnt {
  margin-top: 100px !important;
  margin-right: 100px !important;
  color: #243b2f !important;
}

/* CSS for Download Icon in image dialog */
.css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root {
  margin-right: 40px !important;
  margin-top: 1px !important;
  color: #243b2f !important;
}

.css-39nlm1 {
  margin-right: 40px !important;
  margin-top: 1px !important;
  color: #243b2f !important;
}
