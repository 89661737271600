/* Styles for the navigation bar with search */
.navSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #243b2f;
  padding: 0 10px;
  position: relative;
}
/* Styles for the RBG logo */
.navbar-logo {
  width: 140px;
  height: 70px;
}
/* Styles for the user guide icon button */
.user-guide-icon {
  background-color: transparent;
  border: none;
  color: #fffff5;
  width: auto;
  height: 40px;
  margin-left: 40px;
}
/* Styles for the container of the search input */
.image-data-container {
  height: 40px;
  z-index: 1000;
}

.data-base-stats {
  position: absolute;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  padding: 0 !important;
}
